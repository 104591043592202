exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rental-item-jsx": () => import("./../../../src/pages/rental-item.jsx" /* webpackChunkName: "component---src-pages-rental-item-jsx" */),
  "component---src-pages-user-checkout-js": () => import("./../../../src/pages/user/checkout.js" /* webpackChunkName: "component---src-pages-user-checkout-js" */),
  "component---src-pages-user-home-js": () => import("./../../../src/pages/user/home.js" /* webpackChunkName: "component---src-pages-user-home-js" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

